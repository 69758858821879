import { isTodayInBetween } from "../utils/date"

interface HeroImage {
  titleOne: string
  subTitleOne: string
  imageOne: string
  twitterCardOne: string
  twitterOpenGraphOne: string
}

const useHeroImage = () => {
  const getHeroImage = (): HeroImage => {
    if (isTodayInBetween(new Date("12/1/2024"), new Date("12/31/2024"), true)) {
      return heroImageOptions.winterHoliday
    }
    if (isTodayInBetween(new Date("01/1/2024"), new Date("01/02/2024"), true)) {
      return heroImageOptions.winterHoliday
    }
    if (isTodayInBetween(new Date("06/20/2022"), new Date("07/20/2022"), true)) {
      return heroImageOptions.bestplacetowork
    }
    return heroImageOptions.standard
  }

  return getHeroImage()
}

/** export */
export default useHeroImage

/** mock data */
interface HeroImageOptions {
  /** standard hero image */
  standard: HeroImage

  /** winter holiday hero image */
  winterHoliday: HeroImage

  /** best places to work hero image */
  bestplacetowork: HeroImage
}

const heroImageOptions: HeroImageOptions = {
  standard: {
    titleOne: "Hi. We Are Norhart",
    subTitleOne: "We Design, Build and Rent Apartments",
    imageOne: "/home/norhart-home-hero.png",
    twitterCardOne: "/home/norhart-home-hero-twitter-card.png",
    twitterOpenGraphOne: "/home/norhart-home-hero-open-graph.png",
  },
  winterHoliday: {
    titleOne: "Norhart Apartments",
    subTitleOne: "Wishing You A Wonderful Holiday Season",
    imageOne: "/special-events/winter-holidays/norhart-home-winter-hero.png",
    twitterCardOne: "/special-events/winter-holidays/norhart-winter-hero-twitter-card.png",
    twitterOpenGraphOne: "/special-events/winter-holidays/norhart-winter-hero-open-graph.png",
  },
  bestplacetowork: {
    titleOne: "We Are Norhart",
    subTitleOne: "Top Workplaces Award Winner",
    imageOne: "/special-events/top-place-to-work/norhart-top-place-to-work-hero.png",
    twitterCardOne: "/special-events/top-place-to-work/norhart-top-place-to-work-hero-twitter-card.png",
    twitterOpenGraphOne: "/special-events/top-place-to-work/norhart-top-place-to-work-hero-open-graph.png",
  },
}

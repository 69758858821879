export const isTodayInBetween = (from: Date, to: Date, excludeYear: Boolean = false): Boolean => {
  return isDateInBetween(new Date(), from, to, excludeYear)
}

export const isDateInBetween = (date: Date, from: Date, to: Date, excludeYear: Boolean = false): Boolean => {
  const time = date.getTime()

  if (excludeYear) {
    from.setFullYear(date.getFullYear())
    to.setFullYear(date.getFullYear())
  }

  to.setHours(23)
  to.setMinutes(59)
  to.setSeconds(59)
  to.setMilliseconds(999)

  if (time >= from.getTime() && time <= to.getTime()) {
    return true
  } else {
    return false
  }
}

import React from "react"
import { ColorPalette } from "../Theme"
import Carousel from "react-bootstrap/Carousel"

/** props */
interface Props {
  titleOne: string
  subtitleOne: string
  imageTitleOne: string
  imageOne: string

  titleTwo: string
  subtitleTwo: string
  imageTitleTwo: string
  imageTwo: string

  titleThree: string
  subtitleThree: string
  imageTitleThree: string
  imageThree: string

  titleFour: string
  subtitleFour: string
  imageTitleFour: string
  imageFour: string

  titleFive: string
  subtitleFive: string
  imageTitleFive: string
  imageFive: string

  titleSix: string
  subtitleSix: string
  imageTitleSix: string
  imageSix: string

  colorPalette: ColorPalette
}

/** const */
const HeroTopRotateSection: React.FC<Props> = (props) => {
  return (
    <div
      className="container-fluid text-center hero"
      style={{
        color: "#FFF",
        background: `linear-gradient(${props.colorPalette.heroTop} 30%, ${props.colorPalette.heroBottom} 70%)`,
      }}
    >
      <Carousel controls={false} indicators={false} touch={true} keyboard={true}>
        <Carousel.Item interval={30000}>
          <div className="heroContainer">
            <h3 className="heroTitle">{props.titleOne}</h3>
            <h3 className="heroSubTitle">{props.subtitleOne}</h3>
          </div>
          <img
            loading="eager"
            className="mw-100 h-auto"
            width="800"
            height="400"
            src={props.imageOne}
            alt={props.imageTitleOne}
          />
        </Carousel.Item>

        <Carousel.Item interval={15000}>
          <div className="heroContainer">
            <h3 className="heroTitle">{props.titleTwo}</h3>
            <h3 className="heroSubTitle">{props.subtitleTwo}</h3>
          </div>
          <img
            loading="eager"
            className="mw-100 h-auto"
            width="800"
            height="400"
            src={props.imageTwo}
            alt={props.imageTitleTwo}
          />
        </Carousel.Item>

        <Carousel.Item interval={15000}>
          <div className="heroContainer">
            <h3 className="heroTitle">{props.titleThree}</h3>
            <h3 className="heroSubTitle">{props.subtitleThree}</h3>
          </div>
          <img
            loading="eager"
            className="mw-100 h-auto"
            width="800"
            height="400"
            src={props.imageThree}
            alt={props.imageTitleThree}
          />
        </Carousel.Item>

        <Carousel.Item interval={15000}>
          <div className="heroContainer">
            <h3 className="heroTitle">{props.titleFour}</h3>
            <h3 className="heroSubTitle">{props.subtitleFour}</h3>
          </div>
          <img
            loading="eager"
            className="mw-100 h-auto"
            width="800"
            height="400"
            src={props.imageFour}
            alt={props.imageTitleFour}
          />
        </Carousel.Item>

        <Carousel.Item interval={15000}>
          <div className="heroContainer">
            <h3 className="heroTitle">{props.titleFive}</h3>
            <h3 className="heroSubTitle">{props.subtitleFive}</h3>
          </div>
          <img
            loading="eager"
            className="mw-100 h-auto"
            width="800"
            height="400"
            src={props.imageFive}
            alt={props.imageTitleFive}
          />
        </Carousel.Item>

        <Carousel.Item interval={15000}>
          <div className="heroContainer">
            <h3 className="heroTitle">{props.titleSix}</h3>
            <h3 className="heroSubTitle">{props.subtitleSix}</h3>
          </div>
          <img
            loading="eager"
            className="mw-100 h-auto"
            width="800"
            height="400"
            src={props.imageSix}
            alt={props.imageTitleSix}
          />
        </Carousel.Item>
      </Carousel>
    </div>
  )
}

/** export */
export default HeroTopRotateSection

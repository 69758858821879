import React from "react"
import { Helmet } from "react-helmet"
import config from "../../site.config"
import tagManager from "../utils/tag-manager"
import useHeroImage from "../hooks/useHeroImage"

/** components */
import Layout from "../components/Layout"
import { theme } from "../components/Theme"

/** static sections */
import NewsletterSignup from "../components/static-sections/NewsletterSignupSection"

/** dynamic sections */
import RibbonSection from "../components/dynamic-sections/RibbonSection"
import GalleryImageSection from "../components/dynamic-sections/GalleryImageSection"
import HeroRightSection from "../components/dynamic-sections/HeroRightSection"
import TwoImageSection from "../components/dynamic-sections/TwoImageSection"
import FourImageSmallSection from "../components/dynamic-sections/FourImageSmallSection"
import AwardsSection from "../components/dynamic-sections/AwardsSection"
import GalleryLargeImageSection from "../components/dynamic-sections/GalleryLargeImageSection"
import TwoBoxSection from "../components/dynamic-sections/TwoBoxSection"
import HeroBottomSection from "../components/dynamic-sections/HeroBottomSection"
import HeroTopRotateSection from "../components/dynamic-sections/HeroTopRotateSection"
import GalleryImageLargeSection from "../components/dynamic-sections/GalleryImageLargeSection"
import HeroTopSection from "../components/dynamic-sections/HeroTopSection"

/** svg */
import ApartmentIcon from "../../assets/apartment-icon.svg"
import TeamUpIcon from "../../assets/team-up-icon.svg"
import AmenitiesIcon from "../../assets/amenities-icon.svg"
import BadgeCheckIcon from "../../assets/badge-check-icon.svg"
import NorhartIcon from "../../assets/norhart-icon.svg"
import NorhartDarkCircleIcon from "../../assets/norhart-dark-circle-icon.svg"

/** color theme */
const colorPalette = theme.colorPalettes.blue

/** props */
interface Props {
  data: any
}

/** const */
const homePage: React.FC<Props> = (props) => {
  const { titleOne, subTitleOne, imageOne, twitterCardOne, twitterOpenGraphOne } = useHeroImage()

  tagManager.pushOnce({
    event: "view-home",
  })
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/",
          name: "Apartments For Rent In Forest Lake, Blaine, and Oakdale, MN | Norhart",
          image: `${config.siteMetadata.siteUrl}/home/norhart-home-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Apartments For Rent In Forest Lake, Blaine, and Oakdale, MN | Norhart"
      description="Norhart designs, build, and rents exceptional luxury apartments in the Minneapolis metro area: Forest Lake, Blaine, and Oakdale, MN. Rent or lease a Norhart apartment today."
      keywords="norhart, luxury, innovative, smart, technology, apartment, apartments, building, buildings, mn, minnesota, minneapolis, st paul, rent, rentals, resident, residents, forest lake, blaine, circle pines, lexington, coon rapids, Oakdale, encore apartments, lexington lofts apartments, Oakdale apartments, gateway green apartments, mill pond apartments, birchview apartments, legacy pointe apartments, heritage heights apartments, springwood apartments, greystone apartments, northbrook apartments, construction, norhart construction, lean construction"
      imageTwitter={`${config.siteMetadata.siteUrl}${twitterCardOne}`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}${twitterOpenGraphOne}`}
      colorPalette={colorPalette}
    >
      {/* <HeroTopSection
        title="Hi. We Are Norhart"
        subtitle="We Design, Build and Rent Apartments"
        imageTitle="Sign up for Exclusive Offers"
        image="/home/norhart-home-hero.png"
        colorPalette={colorPalette}
      /> */}

      <HeroTopRotateSection
        titleOne={titleOne}
        subtitleOne={subTitleOne}
        imageTitleOne="Norhart Apartments"
        imageOne={imageOne}
        titleTwo="#NorhartCulture"
        subtitleTwo="Be One Of Us"
        imageTitleTwo="Norhart Home"
        imageTwo="/norhart-life/norhart-life-hero.png"
        titleThree="Our Projects"
        subtitleThree="We Build Apartments Where You Belong"
        imageTitleThree="Norhart Apartments"
        imageThree="/projects/norhart-projects-hero.png"
        titleFour="Let Us Be Your Lifeline"
        subtitleFour="Helping Apartment Residents Is What We 🤍"
        imageTitleFour="Norhart Support"
        imageFour="/support/norhart-support-hero.png"
        titleFive="Exceptional Amenities"
        subtitleFive="Enjoy Norhart's Lifestyle Living"
        imageTitleFive="Norhart Lifestyle Living"
        imageFive="/services/norhart-service-hero.png"
        titleSix={'"Best In Class" Benefits'}
        subtitleSix="We Take Care Of Our People"
        imageTitleSix="Norhart Benefits"
        imageSix="/benefits/norhart-benefits-hero.png"
        colorPalette={colorPalette}
      />

      <RibbonSection
        title="Experience Norhart Apartments"
        tagLine="We are creating a better way for people to live"
        colorPalette={colorPalette}
      />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <TwoBoxSection
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Apartment Life"
        title="We Create That Feeling You Call Home"
        subtitle="At Norhart, our focus is to design an exceptional resident experience end-to-end. We build more than just apartments. We are creating a better way for people to live. We invite you to experience Norhart living!"
        boxImgOne="/apartments/norhart-norhart-oakdale-apartments.webp"
        boxAltOne="Norhart Oakdale Apartments in Oakdale MN"
        boxTitleOne="Norhart Oakdale Apartments"
        boxAddressOne="7600 2rd Street North"
        boxCityOne=" Oakdale, MN 55128"
        boxPhoneOne="612-491-9990"
        boxURLOne="/oakdale/oakdale-apartments/"
        boxImgTwo="/apartments/norhart-encore-apartments-forest-lake.webp"
        boxAltTwo="Lexington Loft Apartments in Blaine MN"
        boxTitleTwo="Lexington Loft Apartments"
        boxAddressTwo="9001 Griggs Ave"
        boxCityTwo="Lexington, MN 55014"
        boxPhoneTwo="651-237-3322"
        boxURLTwo="/blaine/lexington-lofts/"
        colorPalette={colorPalette}
      />

      <GalleryLargeImageSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={ApartmentIcon}
        iconTitle="Nohart Apartments"
        title="Lexington Lofts"
        subtitle="We invite you to be a part of our newest apartment community in Blaine, MN. At Norhart our teams have designed and built an exceptional living experience from the top down just for you!"
        imageTitleOne="Lexington Lofts Image 1"
        imageOne="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-1.webp"
        imageTitleTwo="Lexington Lofts Image 1"
        imageTwo="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-2.webp"
        imageTitleThree="Lexington Lofts Image 3"
        imageThree="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-3.webp"
        imageTitleFour="Lexington Lofts Image 4"
        imageFour="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-4.webp"
        imageTitleFive="Lexington Lofts Image 5"
        imageFive="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-5.webp"
        imageTitleSix="Lexington Lofts Image 6"
        imageSix="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-6.webp"
        imageSeven="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-7.webp"
        imageTitleSeven="Lexington Lofts Image 7"
        imageEight="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-8.webp"
        imageTitleEight="Lexington Lofts Image 8"
        imageNine="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-9.webp"
        imageTitleNine="Lexington Lofts Image 9"
        buttonName="Expore Lexington Lofts"
        buttonUrl="https://www.norhart.com/blaine/lexington-lofts/"
        colorPalette={colorPalette}
        badge={false}
      />

      <TwoImageSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        fontTextColor="#FFFFFF"
        icon={AmenitiesIcon}
        iconTitle="Norhart Amenities"
        title="Can't Miss Experiences"
        subtitle={`If you haven't noticed we do things differently at Norhart. Our "Prime Directive" is to create an exceptional living experience for our residences and take your home life to the next level.`}
        imageTitleOne="Luxurious Pool and Lounge "
        imageOneText="Catch some rays and enjoy the weather! Hard to believe this is your new life. The morning sun cascades down on you and warms your skin. I love that! You are not at a deluxe resort; this is your new home. Wow, this is just amazing!"
        imageOne="/apartments/norhart-apartments-luxurious-pool-and-lounge.webp"
        imageOneAlt="Norhart Lifestyle Living Apartments Outdoor Pool"
        imageOnePill="Series 2 Apartments"
        imageTitleTwo="Tight-Knit Community"
        imageTwoText="We don't just create apartments where it's just a place to sleep. We build apartments where you can create rich relationships and bond with your neighbors. Isn't it time you experienced the Norhart difference for yourself! We think so!"
        imageTwo="/apartments/norhart-apartments-tight-knit-community.webp"
        imageTwoAlt="Norhart Lifestyle Living Apartment Communities"
        imageTwoPill="Series 2 Apartments"
        pillActive={true}
        colorPalette={colorPalette}
        buttonName="Apply Today"
        buttonColor="#FFFFFF"
        buttonText="#333333"
        buttonUrl="https://norhart.twa.rentmanager.com/applynow"
      />

      <HeroRightSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentBottom}
        fontTextColor="#2f5265"
        titleTopLeft="Norhart Living"
        badgeTitle="Great Floor plans"
        title="Beautiful Living Areas"
        subtitle="Choose from one, two, or three-bedroom floor-plans. We carefully designed each space to be functional, luxurious and delightful. #JustForYou!"
        imageTitle="Norhart Apartment Floor Plans"
        image="/apartments/norhart-apartments-floor-plan.png"
        colorPalette={colorPalette}
        colSizeOne={6}
        colSizeTwo={6}
        bottomMargin={5}
        asoEffect="fade"
      />

      <FourImageSmallSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        icon={BadgeCheckIcon}
        title="Apartment Nice Little Extras"
        subtitle="We invite you to choose a Norhart apartment as your next home. We have carefully crafted and curated a living experience that will enhance your life in every way possible."
        imageOnePrice="$55/mo"
        imageHeaderOne="Add-on"
        imageTitleOne="Pet Friendly"
        imageSubTitleOne="We know how important your furry friends are :-)"
        imageOne="/apartments/norhart-apartments-pet-friendly.webp"
        imageOneAlt="Norhart Apartments are Pet Friendly"
        imageTwoPrice="$75+/mo"
        imageHeaderTwo="Add-on"
        imageTitleTwo="Parking"
        imageSubTitleTwo="With underground parking no more cold mornings!"
        imageTwo="/apartments/norhart-apartments-underground-parking.webp"
        imageTwoAlt="Norhart Undergroud Parking"
        imageThreePrice="Free"
        imageHeaderThree="Limited"
        imageTitleThree="Outdoor Balcony"
        imageSubTitleThree="Relax outside and soak up the sun and atmosphere."
        imageThree="/apartments/norhart-apartments-outdoor-balcony.webp"
        imageThreeAlt="Norhart Apartment Balcony"
        imageFourPrice="$50+/mo"
        imageHeaderFour="Limited"
        imageTitleFour="Internet"
        imageSubTitleFour="Plug and play with our in-unit WIFI $50+/mo."
        imageFour="/apartments/norhart-apartments-smart-home.webp"
        imageFourAlt="Norhart Smart Apartment App"
        priceActive={true}
        colorPalette={colorPalette}
        imageExtraOne="/apartments/gallery/extras/extras-1.webp"
        imageTitleExtraOne="Norhart Extra Image 1"
        imageExtraTwo="/apartments/gallery/extras/extras-2.webp"
        imageTitleExtraTwo="Norhart Extra Image 2"
        imageExtraThree="/apartments/gallery/extras/extras-3.webp"
        imageTitleExtraThree="Norhart Extra Image 3"
        imageExtraFour="/apartments/gallery/extras/extras-4.webp"
        imageTitleExtraFour="Norhart Extra Image 4"
        imageExtraFive="/apartments/gallery/extras/extras-5.webp"
        imageTitleExtraFive="Norhart Extra Image 5"
        imageExtraSix="/apartments/gallery/extras/extras-6.webp"
        imageTitleExtraSix="Norhart Extra Image 6"
        asoEffect="fade"
        buttonName="View Apartments"
        buttonUrl="/apartments/"
      />

      <HeroBottomSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Reviews"
        title="Our Residents Say It Best"
        starRating="yes"
        subTitle={`"The Encore Apartments by Norhart are brand new and beautiful!  Located in friendly Forest Lake, they are easy to access and super convenient. Multiple floor plans that have a luxurious feel, with amenities that include a pool area and fitness center" - Lori, Norhart Resident`}
        image="/support/norhart-resident-reviews-1.png"
        imageAlt="Norhart Resident Reviews"
        textColor={colorPalette.textColor}
        asoEffect="zoom-in"
      />

      <HeroRightSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        badgeTitle="Now Hiring"
        buttonName="Learn More"
        buttonUrl="/careers/"
        colorPalette={colorPalette}
        colSizeOne={4}
        colSizeTwo={8}
        fontTextColor="#FFFFFF"
        image="/home/norhart-careers-join-our-team.png"
        imageTitle="Norhart Technology"
        subtitle="Built into Norhart's DNA is the obsession to improve our customer's lives. We love elevating our people's skills, creativity, and drive to build the exceptional."
        title="Join Our A-Team"
        titleTopLeft="Career Opportunities"
        asoEffect="fade"
      />

      <NewsletterSignup colorPalette={colorPalette} />

      <AwardsSection
        header="Norhart Apartments"
        title="What Drives Us"
        subtitle="Our culture and values propel us forward. Always be respectful, results drive success, we love to cultivate innovation, and above all, we succeed together. We fail together. That's how we roll!"
        colorPalette={colorPalette}
      />
    </Layout>
  )
}

/** export */
/** export */
export default homePage
